@import '~bootstrap/scss/bootstrap';

@mixin display-bar {
  text-align: center;
}
.local {
  background-color: slategray;
  @include display-bar;
}
.dev {
  background-color: navy;
  @include display-bar;
}
.prod {
  background-color: darkred;
  @include display-bar;
}
.unknown {
  background-color: darkseagreen;
  @include display-bar;
}